
const $ = require('./node_modules/jquery/dist/jquery.min.js')



/* Variables */
var toggle = '<a href="#" class="toggle"><i class="fas fa-chevron-down"></i></a>';
var container = $('header');



/* Menus */
$('.sub-menu').each(function(){
  $(this).parent().addClass('menu-item-has-children').append(toggle);
});

function setMenu(){
  container.removeClass('mobile');
  if ($('.menu').outerHeight() > $('.menu').children('li').outerHeight()){
    container.addClass('mobile');
  } else {
    container.removeClass('mobile');
    container.removeClass('navigating');
  }
}


/* Class Toggles */
function toggleParentClass(selector, theClass){
  $(selector).click(function(){
    $(this).parent().toggleClass(theClass);
  });
}



/* Init */
$(document).ready(function(){
  setMenu();
  toggleParentClass('.toggle', 'active');
  toggleParentClass('#menu-icon', 'navigating');
  toggleParentClass('#search-icon', 'searching');
});


/* Resize */
var resizeTimer;
var resizeDelay = 200;
var resizeFunctions = function(){
  setMenu();
}

$(window).resize(function(){
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(resizeFunctions, resizeDelay);
});

var lastScroll, scrollMin;

lastScroll = 0;
scrollMin = 60;

$(window).on('scroll', function() {
  var scrolled, yFive, yFour, yOne, ySix, yThree;
  yOne = window.pageYOffset;
  yThree = document.documentElement.scrollTop;
  yFour = document.body.scrollTop;
  yFive = $(window).scrollTop();
  ySix = $(document).scrollTop();
  scrolled = Math.max(yOne, yThree, yFour, yFive, ySix);
  return window.requestAnimationFrame(function() {
//    runP();
    if (scrolled > scrollMin) {
      $('body').addClass('show-menu').addClass('scrolling');
    } else {
      $('body').removeClass('show-menu').removeClass('scrolling');
    }
    if (scrolled < lastScroll && scrolled > scrollMin) {
      $('body').addClass('scrolling-up');
      $('body').removeClass('scrolling-down');
    } else if (scrolled > lastScroll) {
      $('body').addClass('scrolling-down');
      $('body').removeClass('scrolling-up');
    }
    if ( scrolled === 0 ) {
      $('body').removeClass('scrolling-down');
      $('body').removeClass('scrolling-up');
    }
    lastScroll = scrolled;
  });
});







/* Modals */
function overlayInit(){
  $('[data-overlay]').each(function(){
    var id = $(this).attr('data-overlay');
    $(this).on('click', function(){
      $('.overlay[data-id="'+id+'"]').addClass('active');
      $('#overlays').addClass('active');
    });
  });
  $('.overlay').each(function(){
    $(this).find('.toggle').on('click', function(){
      $('#overlays').removeClass('active');
    });
  });
  if (window.location.hash){
    var jump = window.location.hash.substring(1);
    console.log(jump);
    setTimeout(function(){ 
      var offset = 200;
      var top = $(window.location.hash).offset().top;
      if ( !$('#___gatsby').attr('data-scrolled') ){
        $('#___gatsby').scrollTop(top - offset);
        $('#___gatsby').attr('data-scrolled', top - offset);
      }
    }, 500);
    $('.overlay[data-id="'+jump+'"]').addClass('active');
    $('#overlays').addClass('active');
  }
}



function matchHeight(){
  var selector = '.match-height';
  var maxHeight = 0;
  $(selector).each(function(){
    $(this).css({'min-height': '0'});
    maxHeight = Math.max(maxHeight, $(this).height());
  });
  console.log(maxHeight);
  $(selector).each(function(){
    $(this).css({'min-height': maxHeight});
  });
}









exports.onInitialClientRender = () => {

    
    console.log('running')

    setMenu();
    toggleParentClass('.toggle', 'active');
    toggleParentClass('#menu-icon', 'navigating');
    toggleParentClass('#search-icon', 'searching');
    $('section').each(function(){
        var oT = $(this).offset().top;
        if ( oT < $('#app').height() ){
            $(this).addClass('seen');
        }
    });
    overlayInit();
    matchHeight();

    /* Resize */
    var resizeTimer;
    var resizeDelay = 200;
    var resizeFunctions = function(){
    setMenu();
    matchHeight();
    //setQ();
    }

    $(window).resize(function(){
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(resizeFunctions, resizeDelay);
    });


    var lastScroll = 0;
    var scrollMin = 60;
    var offsetPercent = 20; 

    $('#___gatsby').on('scroll', function(){
        var wH = $('#___gatsby').height();
        var yOne = window.pageYOffset;
        var yThree = document.documentElement.scrollTop;
        var yFour = document.body.scrollTop;
        var yFive = $('#___gatsby').scrollTop();
        var ySix = $(document).scrollTop();
        var scrolled = Math.max(yOne, yThree, yFour, yFive, ySix);

        window.requestAnimationFrame(function(){

            if (scrolled > scrollMin){
            $('body').addClass('scrolling');
            } else {
            $('body').removeClass('scrolling');
            }
            if ( scrolled < lastScroll && scrolled > scrollMin){
            $('body').addClass('scrolling-up');
            $('body').removeClass('scrolling-down');
            } else if ( scrolled > lastScroll ){
            $('body').addClass('scrolling-down');
            $('body').removeClass('scrolling-up');
            }
            lastScroll = scrolled;

            $('section:not(.seen)').each(function(){
            var e = $(this);
            var oT = $(e).position().top;
            if ( oT < wH - (wH * (offsetPercent / 100)) ){
                $(e).addClass('seen');
            }
            });

        });
    })


}







exports.onRouteUpdate = ({ location, prevLocation }) => {


    console.log("new pathname", location.pathname)
    console.log("old pathname", prevLocation ? prevLocation.pathname : null)
    
    document.getElementById('___gatsby').scrollTo(0, 0)

    overlayInit()
    matchHeight()
    

    const scrollToTopRoutes = [`/team/`, `/companies/`]
    if (scrollToTopRoutes.indexOf(location.pathname) !== -1) {

      console.log('team or companies page')

        
    }
    if (location.pathname === '/news/') {

      console.log('news page')
        


    }
    if (location.pathname === '/'){

      console.log('welcome home')

      
    }
}


